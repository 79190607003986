
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import CaptionText from '@pixcap/ui-library/components/Typography/CaptionText.vue';
	import Icon360Degree from '@pixcap/ui-library/components/Icons/Icon360Degree.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import IntersectVideoAutoplay from '@pixcap/ui-core/plugins/intersect-component/IntersectVideoAutoplay.vue';

	@Component({
		name: 'PixcapDivThumbnail',
		components: { IntersectVideoAutoplay, BodyText, Icon360Degree, CaptionText },
	})
	export default class PixcapDivThumbnail extends Vue {
		@Prop({ default: true }) readonly isShow: boolean;
		@Prop({ default: false }) readonly showAsVideo: boolean;
		@Prop({ default: true }) readonly showOverlay: boolean;
		@Prop({ default: '' }) readonly imgSrc: string;
		@Prop({ default: null }) readonly videoSrc: string;
		@Prop({ default: '' }) readonly alt: string;
		@Prop({ default: true }) readonly loop: boolean;
		@Prop({ default: true }) readonly autoplay: boolean;
		@Prop({ default: '' }) readonly title: string;
		@Prop({ default: () => [] }) badges: string[];
	}
